/* eslint-disable react/no-unescaped-entities */
const TermsOfService = () => {
  return (
    <div
      style={{
        height: "600px",
        overflowY: "scroll",
        paddingRight: "10px",
        background: "#FDF9D8",
        padding: "2rem",
        borderRadius: "6px",
      }}
      className="scrollbar"
    >
      <div style={{ textAlign: "center" }}>
        <u>Terms of Service</u>
      </div>
      <br />

      <ol>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Acceptance of Terms</b>
          </p>
        </li>
      </ol>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "0.25in",
          marginBottom: "0.11in",
        }}
      >
        This PostcardMania API Terms of Service document (the&nbsp;
        <b>"API TOS"</b>) is an agreement you must accept in order to use the
        PostcardMania API. This document describes both your rights and your
        obligations as part of using the PostcardMania API. It is important that
        you read it carefully because you will be legally bound to these terms.
        Joy Rockwell Enterprises, Inc., a Florida corporation doing business as
        PostcardMania (<b>"PostcardMania" "we" "us"</b>), only provides the
        PostcardMania API to you subject to this API TOS. By accepting this API
        TOS or by accessing or using the PostcardMania API, you agree to be
        bound by this API TOS (including the Privacy Policy, and general Terms
        of Service for Users and Customers, which are incorporated here by
        reference).
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "0.25in",
          marginBottom: "0.11in",
        }}
      >
        If you are entering into this API TOS on behalf of a company or other
        legal entity, you represent that you have the authority to bind such
        entity to this API TOS. In that case, the terms&nbsp;<b>"you"</b>
        &nbsp;or&nbsp;
        <b>"your"</b>&nbsp;shall also refer to such entity. If you do not have
        such authority, or if you do not agree with this API TOS, you may not
        use the PostcardMania API. You acknowledge that this API TOS is a
        contract between you and PostcardMania, even though it is electronic and
        is not physically signed by you and PostcardMania, and it governs your
        use of the PostcardMania API.
      </p>
      <ol start={2}>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>API Key Definitions</b>
            <br />
            <br />
            <br />
          </p>
          <ol>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Application</b>—Any software application, website, or product
                you create or service you offer using the PostcardMania API
                (defined below).
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>API Documentation</b>—The documentation, data and information
                that PostcardMania provides regarding the use of the
                PostcardMania API.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Data</b>—means any data and content uploaded, posted,
                transmitted or otherwise made available by users via the
                Services, including messages, files, comments, profile
                information and anything else entered or uploaded into the
                Service by a user of the Service.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>PostcardMania API or "our API"</b>—The publicly available
                PostcardMania Application Programming Interface ("API") as well
                as the related API Documentation.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>PostcardMania Brand</b>—The PostcardMania brand and brand
                assets, including names, logos, trade names, and trademarks.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>PostcardMania Service(s)</b>—PostcardMania’s business process
                management and related systems and technologies, as well as
                the&nbsp;{" "}
                <a
                  href="https://www.pcmintegrations.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <u>www.PCMintegrations.com</u>
                </a>{" "}
                <span style={{ textDecoration: "none" }}>and </span>{" "}
                <a
                  href="https://portal.pcmintegrations.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>portal.pcmintegrations.com </u>
                </a>
                &nbsp;websites (collectively, the "Site"), and all software,
                applications, data, reports, text, images, and other content
                made available by or on behalf of PostcardMania through any of
                the foregoing. The "Service" does not include Data or any
                software application or service that is provided by you or a
                third party (including Applications), whether or not
                PostcardMania designates them as "official integrations".
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>API License</b>
            <br />
            As long as you follow this API TOS, PostcardMania grants you a
            limited, non-exclusive, non-assignable, non-transferable license to
            use the APIs to develop, test, and support any software application,
            website, or product, and to integrate the PostcardMania APIs with
            your Application. Your license is subject to the limitations set
            forth in the sections below, and you agree that violation of any
            sections will automatically terminate your license to use the
            PostcardMania API.
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Use of APIs and PostcardMania Data</b>
            <br />
            The following identifies limitations on use related to the
            PostcardMania API:
          </p>
          <ol>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Reliance on API.</b>&nbsp;PostcardMania is still evolving,
                and we need the flexibility to occasionally make changes to our
                APIs, including backwards incompatible ones. Also, parts of our
                API are undocumented, including certain methods, events, and
                properties. Given that these undocumented aspects of our API may
                change at any time, you should not rely on these behaviors.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Your Images</b>. PostcardMania uses Adobe products to open
                all images you provide to us. It is your sole responsibility to
                ensure all images you provide will open with Adobe Photoshop or
                InDesign, which must be in .png, .jpeg, or .pdf file formats.
                PostcardMania mails postcards within 1-3 days of receiving a
                properly formatted image from you. PostcardMania is not
                responsible for corrupt or improperly formatted files and any
                delays, including mailing delays, caused by such files.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Applicable laws or agreements.</b>&nbsp;You may not use the
                PostcardMania API in violation of any law or regulation, or
                rights of any person, including but not limited to intellectual
                property rights, rights of privacy, or rights of personality, or
                in any manner inconsistent with this API TOS or PostcardMania’s
                other agreements to which you are subject.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Scope of acceptable use.</b>&nbsp;You may not use the
                PostcardMania API or any other technology in a manner that
                accesses or uses any information beyond what PostcardMania
                allows under this API TOS or the Documentation; that changes the
                PostcardMania Service; that breaks or circumvents any of
                PostcardMania’s technical, administrative, process or security
                measures; that disrupts or degrades the performance of the
                PostcardMania Service or the PostcardMania API; or that tests
                the vulnerability of PostcardMania’s systems or networks.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Malware.</b>&nbsp;You may not transmit any viruses or other
                computer programming that may damage, detrimentally interfere
                with, surreptitiously intercept, or expropriate any system or
                data.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Reverse engineering.</b>&nbsp;You may not attempt to reverse
                engineer or otherwise derive source code, trade secrets, or
                know-how in the PostcardMania API or any portion thereof.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Functionality.</b>&nbsp;You may not use the PostcardMania API
                to replicate or compete with core products or services offered
                by PostcardMania. You acknowledge and agree that PostcardMania
                has or may in the future offer products or services that are
                similar to your Application, and nothing will prevent
                PostcardMania from doing so.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Commercial Use.</b>&nbsp;You may charge for your Application.
                However, you may not sell, rent, lease, sublicense,
                redistribute, or syndicate access to the PostcardMania API.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Advertising.</b>&nbsp;You may&nbsp;<u>not</u>:
              </p>
              <ol>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    Place any advertisements within PostcardMania Service
                    channels, and your advertisements may not resemble or be
                    reasonably likely to confuse users as being a PostcardMania
                    Service message;
                  </p>
                </li>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    Use Data or any content from PostcardMania in any
                    advertisements or for purposes of targeting advertisements,
                    in your Application or elsewhere; or
                  </p>
                </li>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    Use contact information obtained from PostcardMania
                    (including email addresses) to contact PostcardMania users
                    outside of PostcardMania without their and our express
                    permission.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Rate Limits.&nbsp;</b>You will not attempt to exceed or
                circumvent limitations on access, calls and use of PostcardMania
                API, or otherwise use the PostcardMania API in a manner that
                exceeds reasonable request volume, constitutes excessive or
                abusive usage, or otherwise fails to comply or is inconsistent
                with any part of this API TOS or the Documentation.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Use for promotion of gambling or adult content.&nbsp;</b>
                You may not use the PostcardMania APIs in any Application that
                includes adult content, promotes gambling, involves the sale of
                tobacco or alcohol to persons under 21 years of age, or
                otherwise violates any applicable law or regulation.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Distribution of your application.&nbsp;</b>You may not
                distribute or allow access to the PostcardMania APIs to anyone
                other than, if applicable, the company on whose behalf you
                entered into this API TOS. Anyone who wants to access our APIs
                must agree to be bound by this API TOS.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Use of PostcardMania Brand or Marks.&nbsp;</b>You must not
                use the PostcardMania Brand in a way that suggests your service
                is endorsed by, sponsored by, or associated with PostcardMania,
                and you must abide by PostcardMania’s Brand Guidelines available
                on request — by contacting us.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Storage of Data</b>
          </p>
          <ol>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Caching Data.&nbsp;</b>Where Data is cached, you should
                refresh the cache at least every 24 hours.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Secure Storage Measures.&nbsp;</b>All Data should be stored
                and served using strong encryption.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Delete at User Request.&nbsp;</b>You must delete all Data you
                have collected from a PostcardMania user upon request by that
                user, and when the PostcardMania user deauthorizes your
                Application or closes his or her account with you. Similarly,
                when a team stops using your Application, you should delete all
                Data obtained from that team. The restrictions of this Section
                do not apply to Data that PostcardMania users also provide
                directly to you and that is separately entered or uploaded to
                you by the user of your Application.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Deletion at Termination.&nbsp;</b>If we terminate your use of
                the PostcardMania APIs for any reason, then you must permanently
                delete all Data and any other information that you stored
                pursuant to your use of the PostcardMania APIs, except when
                doing so would cause you to violate any law or obligation
                imposed by a governmental authority. This provision does not
                require a PostcardMania user to delete Data stored in
                PostcardMania if the PostcardMania user is prohibited from
                accessing the PostcardMania API.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>No Other Storing.&nbsp;</b>You may not copy or store any Data
                or capture or store any information expressed by the Data (such
                as hashed or transferred data), except to the extent permitted
                by this API TOS.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Your User Agreement and Privacy Policy</b>
            <br />
            If you offer your Application for use by others outside your
            organization, you must maintain a user agreement and privacy policy
            for your Application, which is prominently identified or located
            where users download or access your Application. Your privacy policy
            must meet applicable legal standards and accurately describe the
            collection, use, storage and sharing of data. You must promptly
            notify us of any breaches of your user agreement or privacy policy
            that impact or may impact PostcardMania users. Your privacy policy
            must be at least as stringent and user-friendly as ours.
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Security Measures</b>
          </p>
          <ol>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Protections.</b>&nbsp;The network, operating system and
                software of your web servers, databases, and computer systems
                (collectively, "Your Systems") must be properly configured to
                securely operate your Application and store Data. Your
                Application must use reasonable security measures to protect
                your users’ information. You must not architect or select Your
                Systems in a manner to avoid the foregoing obligation.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Ownership.</b>&nbsp;As between you and us, we own all rights,
            title, and interest, including all intellectual property rights, in
            and to, the (1) PostcardMania APIs, Documentation, and all elements
            and components thereof; (2) Data; (3) PostcardMania Services; and
            (4) Brand Features (collectively, the "PostcardMania Materials").
            The only exception to this is Data that you as a PostcardMania user
            have licensed to PostcardMania under the Terms of Service, which
            governs that Data. Except for the express licenses granted in this
            API TOS, PostcardMania does not grant you any right, title, or
            interest in the PostcardMania Materials. You agree to promptly take
            such actions as PostcardMania may reasonably request to perfect
            PostcardMania’s rights to the PostcardMania Materials.
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Term and Termination</b>
          </p>
          <ol>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Duration of Terms.</b>&nbsp;This API TOS will go into effect
                on the date upon which you agree to them, by accessing or using
                the PostcardMania API, and will continue until terminated as set
                forth herein.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Your Right to Terminate.</b>&nbsp;You may terminate this API
                TOS by discontinuing use of our APIs and the PostcardMania
                Service.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Suspension; Termination.</b>&nbsp;We may change, suspend or
                discontinue the PostcardMania API and suspend or terminate your
                use of the PostcardMania API, the PostcardMania Service, and/or
                PostcardMania Brand at any time and for any reason, without
                notice. Without limiting the foregoing, we may limit your
                Application’s access to the PostcardMania API if it, in our sole
                discretion, may negatively affect our Service or our ability to
                provide our Service.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Effect of Termination.</b>&nbsp;Upon termination of this API
                TOS:
              </p>
              <ol>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    All rights and licenses granted to you will terminate
                    immediately;
                  </p>
                </li>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    You will promptly destroy Documentation and any other
                    PostcardMania information in your possession or control that
                    was received under this API TOS;
                  </p>
                </li>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    Unless we agree otherwise in writing or as stated in this
                    API TOS, you must permanently delete all Data and other
                    information that you stored pursuant to your use of the
                    PostcardMania APIs. PostcardMania may request that you
                    certify in writing your compliance with this section; and
                  </p>
                </li>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    PostcardMania will make commercially reasonable efforts to
                    remove all references and links to your Application from its
                    Services (PostcardMania has no other obligation to delete
                    copies of, references to, or links to your Application).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                The above and following sections of this API TOS shall survive
                any termination.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Other Important Terms.</b>
          </p>
          <ol>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Legal Representations.&nbsp;</b>You represent and warrant to
                PostcardMania that, excluding PostcardMania Materials, you have
                the right to use, reproduce, transmit, copy, publicly display,
                publicly perform, and distribute your Application, and that use
                of your Application by PostcardMania and its users will not
                violate the rights of any third party (e.g., copyright, patent,
                trademark, privacy, publicity or other proprietary right of any
                person or entity), or any applicable regulation or law,
                including the Digital Millennium Copyright Act, the laws of any
                country in which your Application is made available and any
                applicable export laws.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Modification of the API TOS.&nbsp;</b>We may change, add to
                or delete this API TOS or any portion thereof from time to time
                in our sole discretion. If we make a material change to this API
                TOs, we will provide you with reasonable notice prior to the
                changes either by emailing the email address associated with
                your account or by posting a notice on the PostcardMania
                website. You acknowledge that these updates and modifications
                may adversely impact how you access, use, and communicate with
                the PostcardMania API. If any change is unacceptable to you,
                then your only recourse is to cease all use of the PostcardMania
                API. Your continued access or use of the PostcardMania API will
                mean that you agree to the updates and modifications.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Other Applicable Agreements.&nbsp;</b>You and your
                Application must also comply with the following, which are
                hereby incorporated by reference:
              </p>
              <ol>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    PostcardMania Terms of Service for Users and Customers.
                  </p>
                </li>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    PostcardMania Privacy Policy
                  </p>
                </li>
                <li>
                  <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                    PostcardMania Brand Guidelines (available on request)
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "-0.25in",
          marginBottom: "0.11in",
        }}
      >
        In the event of any conflict between the content in this document and
        the above documents, this document controls your use of the
        PostcardMania APIs.
      </p>
      <ol start={11}>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Disclaimer of Warranties; Limitation of Liability; Indemnity</b>
          </p>
          <ol>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <u>
                  <b>NO WARRANTIES.</b>
                </u>
                &nbsp;THE POSTCARDMANIA API AND ALL RELATED COMPONENTS AND
                INFORMATION ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS
                WITHOUT ANY WARRANTIES OF ANY KIND, AND POSTCARDMANIA EXPRESSLY
                DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT POSTCARDMANIA DOES NOT
                WARRANT THAT THE POSTCARDMANIA API WILL BE UNINTERRUPTED,
                TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE, NOR DOES IT MAKE ANY
                WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
                POSTCARDMANIA API, AND NO INFORMATION, ADVICE OR SERVICES
                OBTAINED BY YOU FROM POSTCARDMANIA OR THROUGH THE DEVELOPER SITE
                SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS API TOS.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <u>
                  <b>LIMITATION ON LIABILITY.</b>
                </u>
                &nbsp;UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER
                IN CONTRACT, TORT, OR OTHERWISE) SHALL POSTCARDMANIA BE LIABLE
                TO YOU OR ANY THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL,
                SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
                LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, OR (B) FOR ANY
                AMOUNT IN THE AGGREGATE IN EXCESS OF THE FEES ACTUALLY PAID BY
                YOU IN THE SIX (6) MONTHS PRECEDING THE EVENT GIVING RISE TO
                YOUR CLAIM OR, IF NO FEES APPLY, ONE HUNDRED ($100) U.S.
                DOLLARS, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. THE
                PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THIS TOS
                BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THESE
                LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THIS TOS. Some
                jurisdictions do not allow the exclusion of implied warranties
                or limitation of liability for incidental or consequential
                damages, which means that some of the above limitations may not
                apply to you. IN THESE JURISDICTIONS, POSTCARDMANIA’S LIABILITY
                WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <u>
                  <b>Indemnity.</b>
                </u>
                &nbsp;You agree to defend, hold harmless and indemnify
                PostcardMania, and its subsidiaries, affiliates, officers,
                agents, employees, and suppliers, from and against any third
                party claim arising from or in any way related to your or your
                users’ use of the Application, PostcardMania API or Data, use of
                PostcardMania Brand, or violation of this API TOS, including any
                liability or expense arising from all claims, losses, damages
                (actual and consequential), suits, judgments, litigation costs
                and attorneys’ fees, of every kind and nature. In such a case,
                we will provide you with written notice of such claim, suit, or
                action.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>Dispute Resolution</b>
            <br />
            This API TOS and any claim, cause of action or dispute ("Claim")
            arising out of or related to this Agreement shall be governed by the
            laws of the state of Florida, United States, regardless of your
            country of origin or where you access the PostcardMania Service, and
            notwithstanding any conflicts of law principles and the United
            Nations Convention for the International Sale of Goods. You and
            PostcardMania agree that all Claims arising out of or related to
            this Agreement must be resolved exclusively in the courts located in
            the City of Clearwater, Florida. You and PostcardMania agree to
            submit to the personal jurisdiction of the courts located within the
            City of Clearwater, Florida – and agree to waive any and all
            objections to the exercise of jurisdiction over the parties by such
            courts and to venue in such courts for the purpose of litigating all
            such Claims. Notwithstanding the above, you agree that PostcardMania
            shall still be allowed to apply for injunctive remedies (or an
            equivalent type of urgent legal relief) in any jurisdiction.
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <b>General Legal Terms</b>
          </p>
          <ol>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Severability.</b>&nbsp;If any provision of this API TOS is
                found to be illegal, void, or unenforceable, the unenforceable
                provision will be modified so as to render it enforceable to the
                maximum extent possible in order to effect the intention of the
                provision; if a term cannot be so modified, it will be severed
                and the remaining provisions of this API TOS will not be
                affected in any way.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Language.</b>&nbsp;Where PostcardMania has provided you with
                a translation of the English language version of this API TOS or
                any document referenced in this API TOS, you agree that the
                translation is provided for your convenience only and that the
                English language versions of any such document, will control.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Notice and Service of Process.&nbsp;</b>We may notify you via
                email, mail, overnight courier, within the PostcardMania API, or
                by any other electronic method. You must notify us by overnight
                courier or certified mail, return receipt requested, to
                PostcardMania, Attn: Will Thorn, 2145 Sunnydale Blvd,
                Clearwater, FL 33765{/*  */}. Any notices that you provide
                without compliance with this section shall have no legal effect.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Entire Agreement.&nbsp;</b>This API TOS and any documents
                incorporated into this API TOS by reference, constitute the
                entire agreement between you and PostcardMania regarding the
                PostcardMania APIs and supersedes all prior agreements and
                understandings, whether written or oral, or whether established
                by custom, practice, policy or precedent, with respect to the
                subject matter of this API TOS.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>No Informal Waivers, Agreements, or Representations.</b>
                &nbsp;Our failure to act with respect to a breach of this API
                TOS by you or others does not waive our right to act with
                respect to that breach or subsequent similar or other breaches.
                No representations, statements, consents, waivers or other acts
                or omissions by any PostcardMania affiliate shall be deemed
                legally binding on any PostcardMania affiliate, unless
                documented in a physical writing hand signed by a duly appointed
                officer of PostcardMania.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Injunctive Relief.&nbsp;</b>In no event shall you seek or be
                entitled to rescission, injunctive or other equitable relief, or
                to enjoin or restrain the operation of the Developer Site or
                PostcardMania Service or any PostcardMania APIs, or other
                material used or displayed through the PostcardMania Developer
                Site or PostcardMania Service.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Assignment and Delegation.&nbsp;</b>You may not assign or
                delegate any rights or obligations under this API TOS, including
                in connection with a change of control. Any purported assignment
                and delegation shall be ineffective. We may freely assign or
                delegate all rights and obligations under this API TOs, fully or
                partially without notice to you. We may also substitute, by way
                of unilateral novation, effective upon notice to you,
                PostcardMania, Inc. for any third party that assumes our rights
                and obligations under this API TOs.
              </p>
            </li>
            <li>
              <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
                <b>Potential Other Rights and Obligations.</b>
                <br />
                You may have rights or obligations under local law other than
                those enumerated here if you are located outside the United
                States.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <b>Addendum</b>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <b>Refund Policy</b>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        I acknowledge that I cannot be refunded for services that have been
        approved for delivery. Once you have sent approval for a specific
        product (list, design, print product, etc.) the money paid for those
        services is fully allocated and the product can no longer be altered,
        canceled or refunded. In some cases, even if no approval has been given,
        PostcardMania reserves the right to offer credit on account or partial
        refund if costs have been incurred.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <b>PostcardMania and the United States Postal Service:</b>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        I understand that PostcardMania is a separate entity from the United
        States Postal Service.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Once your mail or your customer’s mail is in the hands of the USPS,
        PostcardMania can no longer control or change any aspect of the mailing.
        You also acknowledge that the USPS does not provide written proof of
        mailings or delivery for individual mail owners. The USPS provides us
        with your customer’s mail tracking data as they scan your customer’s
        mail pieces which will be automatically updated to your customer’s
        storefront account.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        There are times when we do not have confirmed scans due to their sorting
        procedures but the USPS will still deliver your customer’s mail even if
        we cannot provide tracking data.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Once your customer’s mail has been given to the USPS, PostcardMania
        cannot cancel the mailing or refund the order.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <b>Post Office Delivery Times:</b> The delivery date given is
        approximate based on the normal turnaround time.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        PostcardMania is not responsible for delays caused by the USPS and our
        responsibility ends when the printed mail piece is delivered to the Post
        Office. Under no circumstances is a refund or credit issued for the cost
        of the order after the product has been dropped at the Post Office.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <b>Postage Increases:</b>When the USPS increases postage rates, we will
        pass along that increase by raising API pricing. You will be notified of
        the rate increase.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <b>Mailing Lists:</b>
        The industry average for return on non-deliverable mail is 10%.
        Unfortunately, people move every day and they do not always let the USPS
        know. Therefore, sometimes there is a delay in updating where the
        consumer or business has moved to.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        If you ordered and mailed your cards out through PostcardMania, we will
        assume responsibility for any amount above 10% if the list has been used
        within a year of purchase.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        PostcardMania will give a credit toward future mailing for any amount
        returned over 10% on lists purchased through PostcardMania. This
        includes the printing, addressing, list and postage fees over the 10%.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        You must save and return all of your mail pieces in order to receive the
        credit listed above as we need to return these to the third-party list
        compiler.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        PostcardMania will have no liability to you for returns related to a
        list provided by you.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        PostcardMania is not responsible for the maintaining or storage of
        mailing lists you have provided to us or purchased through us beyond the
        current mailing.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        However, we will make every effort to keep your personal mailing list(s)
        and any unlimited lists you purchased from us for a period of (1) year.
        After (1) year, any mailing lists we have on file for you will no longer
        be kept on file.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <b>Custom Design Services:</b>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Designs purchased are for one size and we will deliver a jpeg file for
        the front and back of the postcard for static designs, or configure the
        design in your PCM Integrations portal for variable designs.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        That design will have a cap of 10 iterations (design changes). Sizes can
        be selected from: 4 in. X 6 in., 4.25 in. X 6 in., 6 in. X 8.5 in., 6
        in. X 9 in., 6 in. X 11 in and 8.5 in. X 11 in.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
    </div>
  );
};

export default TermsOfService;
