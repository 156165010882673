export default async function downloadFileJS(path, contentType, fileName) {
  const res = await fetch(path, {
    method: "GET",
    headers: {
      "Content-Type": contentType,
    },
  });
  console.log(res);
  const blob = await res.blob();

  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}
