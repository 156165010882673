import { Canvas } from "fabric/fabric-impl";
import { fabric } from "fabric";

export function createBleedClipPath(canvas: Canvas) {
  const background = canvas._objects.find((x) => x.name === "bleed");
  if (background) {
    return new fabric.Rect({
      height: background.height,
      width: background.width,
      left: background.left,
      top: background.top,
      absolutePositioned: true,
    });
  }
  return undefined;
}
